<template>
  <div class="profile">
    <div class="header">
      <div class="profile-pic">
        <div class="profile-pic__img profile-pic__blob">
          <img
            :src="contentProfile.logo"
            :alt="contentProfile.name"
          >
        </div>
      </div>
    </div>
    <div class="name-wrapper">
      <h1>{{ contentProfile.name }} <br>{{ contentProfile.prenom }}</h1>
    </div>
    <div class="contact-info clearfix">
      <ul class="list-titles">
        <li>Tel</li>
        <li>Email</li>
        <li>Web</li>
        <li>Permis</li>
        <li>Adresse</li>
      </ul>
      <ul class="list-content ">
        <li>{{ contentProfile.tel }}</li>
        <li>{{ contentProfile.mail }}</li>
        <li>
          <a
            :href="contentProfile.website"
            target="_blank"
            class="disabled"
          >{{ contentProfile.website }}</a>
        </li>
        <li>{{ contentProfile.permis }}</li>
        <li>{{ contentProfile.adresse }}</li>
      </ul>
    </div>
    <div class="contact-presentation">
      <p>{{ contentProfile.description }}</p>
    </div>
    <div class="contact-social">
      <div class="list-social">
        <a
          v-for="social in contentProfile.socials"
          :key="social.id"
          :href="social.url"
          target="_blank"
        >
          <vue-feather :type="social.name" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProfilePage',
  components: {},
  props: {
    contentProfile: {
      type: Object
    }
  },
  setup () {
    return {}
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Varela+Round);

.clearfix::after, .clearfix::before {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.profile {
  position:relative;
  display: block;
  height: 100vh;
  padding:24px;
  box-sizing: border-box;
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    color:#2f2f30;
  }
  .name-wrapper {
    width: 100%;
    h1 {
      font-size: 2.5em;
      text-align: left;
      font-family: "Varela Round", sans-serif;
      color: #4a4e51;
      text-transform: uppercase;
      line-height: 1em;
      padding:40px;
    }
  }

  .contact-info {
    display: flex;
    margin-top: 50px;
    font-weight: 300;
  }
  .list-titles {
    text-align: left;
    font-weight: 600;
    width: max-content;
    color: #4a4e51;
  }
  .list-social {
    position:sticky;
    bottom:0;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    width:100%;
    margin:0;
    padding: 0;
    a {
      color: #4a4e51;
      margin: auto 12px;
      &:hover {
        color: #2f2f30;
      }
    }
  }
  .list-content {
    width: 100%;
    text-align: left;
    font-weight: 300;
  }
  .contact-presentation {
    display: block;
    width: 100%;
    text-align: left;
    padding:0 40px;
    box-sizing: border-box;
    font-weight: 300;
    margin: 50px 0;
  }
}

// Logo bg animate

.profile-pic {
  position: relative;
  width:100%;
}
.profile-pic__img {
  border-radius: 50%;
  overflow: hidden;
}
.profile-pic__blob {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  width: 200px;
  height: 200px;
  margin:auto;
  background: rgba(16,16,20,1);
  background-size: cover;
  animation: blob-animate 8s linear infinite;
  > img {
    max-width: 80%;
    margin:auto;
  }
}

.light {
  .profile-pic__blob {
    background:white;
  }
}

@keyframes blob-animate {
  0%, 100% {
    border-radius: 74% 56% 47% 73%;
  }
  25% {
    border-radius: 73% 27% 26% 38%/73% 36% 64% 27%;
  }
  50% {
    border-radius: 28% 72% 44% 56%/49% 40% 60% 51%;
  }
  75% {
    border-radius: 40% 60% 70% 30%/47% 62% 38% 53%;
  }
}

@media screen AND (max-width:840px) {
  .profile {
    height: auto;
  }
}
</style>
