<template>
  <div class="layout">
    <n-layout
      class="sidebar"
      :native-scrollbar="true"
    >
      <my-profile
        :content-profile="informations"
      />
    </n-layout>
    <n-layout
      class="contents"
      content-style="padding: 24px;"
      :native-scrollbar="false"
    >
      <my-resume :content-resume="resume" />
    </n-layout>
  </div>
</template>

<script>
// @ is an alias to /src
import myProfile from '@/components/Profile.vue'
import myResume from '@/components/Resume.vue'

// NaiveUI
import { NLayout } from 'naive-ui'
export default {
  name: 'Home',
  components: {
    myProfile,
    myResume,
    // UI:
    NLayout
  },
  setup () {
    return {
      informations: {
        name: 'Centurioni',
        prenom: 'Benoit',
        anniversaire: '30/06/1990',
        tel: '06.58.35.23.67',
        mail: 'centurioni.benoit@gmail.com',
        adresse: 'Grenoble, France',
        website: 'https://nihilo.fr',
        permis: 'Permis B (Sans véhicule)',
        logo: '/img/N1.png',
        logoDark: 'https://nihilo.fr/storage/N1_contrast.png',
        description: "Passioné par l'informatique depuis toujours, j'ai entrepris, en autodidacte, la progarmmation web / webapp.",
        socials: [
          { name: 'linkedin', pseudo: 'Benoit', url: 'https://www.linkedin.com/in/benoit-c-0351911a3/' },
          { name: 'facebook', pseudo: 'Nihilo.FR', url: 'https://facebook.com/nihilo.fr' },
          { name: 'github', pseudo: 'Nihilop', url: 'https://github.com/nihilop' }
        ]
      },
      resume: {
        experiences: [
          { company: 'Sotranasa (66)', dates: '2019 - 2020', poste: "Chargé d'étude FTTH", description: 'Etudes de réseau fibre optique aérien ENEDIS / ORANGE' },
          { company: 'Nihilo.fr (38)', dates: '2015 - 2018', poste: 'Développeur web', description: 'Auto entrepreneur, création de sites web, création de webapps, mise en place de serveurs web, création de logos' },
          { company: 'IMM Informatique (31)', dates: '2013', poste: 'Technicien informatique', description: 'Maintenance & vente de produits informatiques' }
        ],
        projects: [
          { name: 'dash', cover: '/img/DashLauncher.png', link: 'https://dash.nihilo.fr', description: "Lanceur d'applications personnalisé" },
          { name: 'discordeno', cover: '/img/codebase.png', link: 'https://github.com/nihilop', description: 'Code note client + API(denojs)' },
          { name: 'Animstriim', cover: '/img/animstriim.png', link: 'https://github.com/nihilop', description: 'Lecteur de vidéo animé' },
          { name: 'SCF', cover: '/img/SCF.png', link: 'https://github.com/nihilop', description: 'Communauté Star citizen Francophone' }
        ],
        competences: [
          { language: 'HTML5', value: 100, status: 'success' },
          { language: 'CSS3/SASS', value: 95, status: 'success' },
          { language: 'Javascript', value: 85, status: 'success' },
          { language: 'VueJS 2/3', value: 80, status: 'success' },
          { language: 'ElectronJS', value: 80, status: 'success' },
          { language: 'Photoshop', value: 50, status: 'warning' }
        ],
        other: ['Anglais', 'Français', 'Linux', 'Windows', 'Github', 'GitLab', 'NaiveUI', 'Tailwind', 'Bootstrap', 'Nodejs', 'ElectronJS', 'Jquery', 'Element.io', 'UiKit', 'Suites bureautique', 'Docker', 'UE4', 'Unity', 'Blender', 'Design UI/UX', 'DenoJS', 'DiscordAPI'],
        etudes: [
          { ecole: 'Auto formation', diplome: 'Développeur web', date: '2012' },
          { ecole: 'Joseph fourier', diplome: 'DEAU - B', date: '2011 - 2012' },
          { ecole: 'Thomas Edison', diplome: 'BEP - Electrotechnique', date: '2006 - 2008' }
        ],
        hobbies: [
          { title: 'Informatique', description: "Passionné par l'informatique, j'ai beaucoup diversifié mes compences, tant dans les jeux (UE4) que les serveurs (Debian 8/9/10) et bien sûr, dans la programmation" },
          { title: 'Sports', description: "J'aime les balades en montagne & la course à pieds." }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.layout {
  display: flex;
  min-height: 100vh;
  .sidebar {
    width: 550px;
    background:white;
    color: inherit !important;
  }
  .contents {
    width:100%;
    height: 100vh;
  }
}

.light {
  .sidebar {
    background: #f4f4f4;
  }
}

@media screen AND (max-width:840px) {
  .layout {
    flex-direction: column;
    .sidebar {
      width: 100%;
    }
    .contents {
      height:100%;
    }
  }
}
</style>
