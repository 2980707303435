<template>
  <div class="resume">
    <h1># Derniers Projets</h1>
    <n-grid
      cols="2 200:1 500:2 700:3"
      y-gap="12"
      x-gap="12"
    >
      <n-image-group>
        <n-grid-item
          v-for="project in contentResume.projects"
          :key="project.id"
          style="display:flex; justify-content:center; vertical-align:middle;"
        >
          <a :href="project.link" target="_blank" style="text-decoration:none">
            <img
              class="recent_project"
              style="width:90%; max-height:180px; border-radius:8px;"
              :src="project.cover"
            >
            <p style="color: gray;">{{ project.description }}</p>
          </a>
        </n-grid-item>
      </n-image-group>
    </n-grid>
    <h1># Compétences</h1>
    <n-list bordered>
      <n-list-item
        v-for="(skill, index) in contentResume.competences"
        :key="index"
        class="item_list"
      >
        <template #prefix>
          <n-h2 class="skills">
            {{ skill.language }}
          </n-h2>
        </template>
        <n-progress
          :type="skill.circle ? 'circle' : 'line'"
          :status="skill.status"
          :percentage="skill.value"
          :indicator-placement="'inside'"
        />
      </n-list-item>
    </n-list>
    <span class="otherComp"> Autres compétences </span>
    <n-space>
      <n-tag
        v-for="(other, index) in contentResume.other"
        :key="index"
      >
        {{ other }}
      </n-tag>
    </n-space>
    <h1># Expériences</h1>
    <n-list>
      <n-list-item
        v-for="(exp, index) in contentResume.experiences"
        :key="index"
        class="item_list"
      >
        <template #prefix>
          <n-h2 class="date">
            {{ exp.dates }}
          </n-h2>
        </template>
        <n-thing
          :title="exp.poste"
        >
          <b>{{ exp.company }}</b> - {{ exp.description }}
        </n-thing>
      </n-list-item>
    </n-list>
    <h1># Etudes</h1>
    <n-list>
      <n-list-item
        v-for="(etd, index) in contentResume.etudes"
        :key="index"
        class="item_list"
      >
        <template #prefix>
          <n-h2 class="date">
            {{ etd.date }}
          </n-h2>
        </template>
        <n-thing
          :title="etd.ecole"
          :description="etd.diplome"
        />
      </n-list-item>
    </n-list>
    <h1># Hobbies</h1>
    <n-list>
      <n-list-item
        v-for="(hob, index) in contentResume.hobbies"
        :key="index"
        class="item_list"
      >
        <n-thing
          :title="hob.title"
          :description="hob.description"
        />
      </n-list-item>
    </n-list>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { NList, NListItem, NThing, NH2, NProgress, NGrid, NGridItem, NImageGroup, NSpace, NTag } from 'naive-ui'
export default defineComponent({
  name: 'ResumePage',
  components: {
    NList,
    NListItem,
    NThing,
    NH2,
    NProgress,
    NGrid,
    NGridItem,
    NImageGroup,
    NSpace,
    NTag
  },
  props: {
    contentResume: {
      type: Object
    }
  },
  setup () {
    return {}
  }
})
</script>
<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Varela+Round);

.resume {
  height: auto;
  max-width: 1200px;
  margin:auto;
  text-align: left;
  .date, .skills {
    font-weight: 200;
    font-size: 0.9em;
    margin:auto;
    display: block;
    min-width: 150px;
    font-family: "Varela Round", sans-serif !important;
  }
  .otherComp {
    display: block;
    font-size:1.1em;
    opacity: 0.5;
    padding: 12px 24px;
  }
  .recent_project {
    transition:all 0.5s;
    border: 4px solid transparent;
    &:hover {
      transform:  scale(1.1);
      box-shadow: 0 0 5px 4px rgba(black, 5%);
      border: 4px solid #3498db;
    }
  }
}

@media screen AND (max-width:840px) {
  .date, .skills {
    min-width: 100px !important;
  }
}
</style>
